import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  HeaderTitleWithLines,
} from './elements'

import awardTitleMainBG from '../images/nagroda-glowna.svg'
import doubleArrowBG from '../images/double-arrow-red.svg'

const AwardBag = styled.div`
  margin: 30px auto 0;
  width: 70%;
`

const AwardsWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 60px;
  margin-top: -28%;
  @media (min-width: 1200px) {
    margin-top: -16%;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-top: -18%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: -28%;
  }
  @media (max-width: 575px) {
    margin-top: -45%;
  }
`

const AwardsContainer = styled.div`
  ${ContainerMixin}
`

const AwardsRow = styled.div`
  ${RowMixin}
`

const AwardsCol = styled.div`
  ${ColMixin}
`

const AwardsColHeader = styled(AwardsCol)`
  /* overflow: hidden; */
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 100px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-bottom: 25px;
  }
`

const AwardsHeaderTitle = styled(HeaderTitleWithLines)``

const AwardsColDesc = styled(AwardsCol)`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 10.51%;
  padding-right: 5.257%;
  ${DescMixin}
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AwardsDescWrapper = styled.div`
  position: relative;
  @media (max-width: 991px) {
    text-align: center;
    padding-bottom: 30px;
  }
  h3,
  p {
    position: relative;
    z-index: 20;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  h3 {
    position: relative;
    display: inline-block;
    z-index: 20;
    @media (min-width: 1600px) {
      padding-top: 12px;
      margin-bottom: 45px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 10px;
      margin-bottom: 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 5px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: 575px) {
      padding-top: 50px;
      margin-bottom: 40px;
    }
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -80px;
      right: -50px;
      bottom: -40px;
      background-image: url(${awardTitleMainBG});
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: contain;
      z-index: -10;
    }
  }
  p {
    strong {
      span {
        color: ${(props) => props.theme.colorGreen};
      }
    }
  }
`

const AwardsDescInnerWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  z-index: 10;
  @media (min-width: 1600px) {
    padding-left: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 20px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`

const AwardsColDescImage = styled(AwardsCol)`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }

  .additional {
    height: auto;
    width: 70%;
  }
`

const AwardsHeaderComplectsQtyWrapper = styled.div`
  text-align: right;
  @media (max-width: 991px) {
    display: none;
    text-align: center;
  }
`

const AwardsHeaderComplectsQtyWrapperMobile = styled(
  AwardsHeaderComplectsQtyWrapper
)`
  margin-top: -40px;
  margin-bottom: 30px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: block;
  }
`

const AwardsHeaderComplectsQty = styled.div`
  position: relative;
  display: inline-block;
  /* color: #8f8f8f; */
  color: rgba(204, 204, 204, 0.8);
  /* font-family: 'ff-meta-correspondence-web-p', sans-serif; */
  font-size: 220px;
  font-weight: 700;
  font-style: normal;

  text-transform: uppercase;
  line-height: 1;
  padding-right: 30px;

  @media (min-width: 992px) {
    margin-top: -150px;
    ${(props) =>
      props.type === 'additional' &&
      `
      margin-top: -180px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 160px;
    padding-right: 32px;
    margin-top: -108px;
    ${(props) =>
      props.type === 'additional' &&
      `
      margin-top: -145px;
    `}
  }
  @media (max-width: 991px) {
    padding-right: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 110px;
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 100px;
    padding-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 80px;
    padding-top: 15px;
  }
  span {
    display: inline-block;
    &:first-of-type {
      vertical-align: text-top;
    }
  }
  .qtyWrapper {
    display: inline-block;
  }
  .qtyText {
    right: -35px;
    bottom: 120px;
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 22px;

    ${(props) =>
      props.type === 'main' &&
      `
      letter-spacing: 5px;
    `}
    ${(props) =>
      props.type === 'additional' &&
      `
      bottom: 120px;
      font-size: 22px;
    `}
    @media (min-width: 992px) {
      position: absolute;
      transform: rotate(-90deg);
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -25px;
      bottom: 89px;
      font-size: 17px;
      ${(props) =>
        props.type === 'main' &&
        `
      bottom: 89px;
      font-size: 16px;
      letter-spacing: 5px;
    `}
      ${(props) =>
        props.type === 'additional' &&
        `
      font-size: 16px;
    `}
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 20px;
      margin-top: -15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
      margin-top: -12px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      margin-top: -10px;
    }
  }
`

const ImgAwardsMore = styled(Img)`
  float: right;
  height: auto;
  margin-bottom: 120px;
  margin-right: 70px;
  width: 70%;

  @media (max-width: 992px) {
    float: none;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
  }
`

const AwardsArrowDivider = styled.div`
  width: 100%;
  height: 80px;
  background-image: url(${doubleArrowBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -80px;
  margin-bottom: 100px;
  @media (max-width: 991px) {
    height: 20px;
    background-image: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    height: 10px;
  }
  @media (max-width: 575px) {
    height: 1px;
  }
`

const Awards = () => {
  const data = useStaticQuery(graphql`
    query {
      awardsImage: file(relativePath: { eq: "awards.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      awardsMoreAwardsImage: file(relativePath: { eq: "awards-more.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      awardsBagImage: file(relativePath: { eq: "awards-bag.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <AwardsWrapper id="awards">
      <AwardsContainer>
        <AwardsRow>
          <AwardsColHeader>
            <AwardsHeaderTitle>
              <span className="textWrapper">
                <span className="line first left" />
                <span className="line middle left" />
                <span className="line last left" />
                <span>Nagrody</span>
                <span className="line first right" />
                <span className="line middle right" />
                <span className="line last right" />
              </span>
            </AwardsHeaderTitle>
          </AwardsColHeader>
        </AwardsRow>
        <AwardsRow>
          <AwardsColDesc>
            <AwardsDescWrapper>
              <AwardsDescInnerWrapper type="main">
                <h3>Nagroda główna</h3>
                <p>
                    Na 60 najlepszych zawodników czeka <strong>sportowy event przepełniony rywalizacją z
                    {'\u00A0'}
                    <span>Tomkiem Kucharem</span></strong>
                </p>
                <p>
                  <strong>LUB <span>ultradźwiękowa myjnia do{'\u00A0'}narzędzi</span> - dzięki mocy 500{'\u00A0'}W, zmiennym
                    częstotliwościom i{'\u00A0'}interwałowym
                    trybie pracy</strong> już nie ubrudzisz sobie
                    rąk w{'\u00A0'}warsztacie!
                </p>
              </AwardsDescInnerWrapper>
            </AwardsDescWrapper>
          </AwardsColDesc>
          <AwardsColDescImage>
            <AwardsHeaderComplectsQtyWrapperMobile type="main">
              <AwardsHeaderComplectsQty type="main">
                <div className="qtyWrapper">
                  <span>60</span> <span className="qtyText">nagród</span>
                </div>
              </AwardsHeaderComplectsQty>
            </AwardsHeaderComplectsQtyWrapperMobile>
            <Img fluid={data.awardsImage.childImageSharp.fluid} />
            <AwardsHeaderComplectsQtyWrapper>
              <AwardsHeaderComplectsQty type="main">
                <div className="qtyWrapper">
                  <span>60</span> <span className="qtyText">nagród</span>
                </div>
              </AwardsHeaderComplectsQty>
            </AwardsHeaderComplectsQtyWrapper>
          </AwardsColDescImage>
          <AwardsArrowDivider />
        </AwardsRow>
        <AwardsRow>
          <AwardsColDesc>
            <AwardsDescWrapper>
              <AwardsDescInnerWrapper type="additional">
                <h3>Nagrody tygodnia – Niezbędnik RALLYEXPERTA</h3>
                <p>
                  <strong>
                    <span>1600 nagród</span> dla zawodników, którzy zajmą
                    punktowane miejsca w{'\u00A0'}tygodniowych rankingach.
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>
                    Do wygrania m.in. setki firmowych <span>softshelli, kamizelek, lamp
                    halogenowych</span> do{'\u00A0'}oświetlenia kanału
                    warsztatowego, przydatnych <span>zestawów
                    podkładów na{'\u00A0'}nadkola i{'\u00A0'}wiele innych!</span> A także... wyjątkowa, firmowa torba</strong> do{'\u00A0'}której będziesz mógł spakować
                    wszystkie nagrody!
                </p>

                <AwardBag>
                  <Img fluid={data.awardsBagImage.childImageSharp.fluid} />
                </AwardBag>
              </AwardsDescInnerWrapper>
            </AwardsDescWrapper>
          </AwardsColDesc>
          <AwardsColDescImage>
            <AwardsHeaderComplectsQtyWrapperMobile type="additional">
              <AwardsHeaderComplectsQty type="additional">
                <div className="qtyWrapper">
                  <span>1600</span> <span className="qtyText">zestawów</span>
                </div>
              </AwardsHeaderComplectsQty>
            </AwardsHeaderComplectsQtyWrapperMobile>
            <ImgAwardsMore
              fluid={data.awardsMoreAwardsImage.childImageSharp.fluid}
            />
            <AwardsHeaderComplectsQtyWrapper>
              <AwardsHeaderComplectsQty type="additional">
                <div className="qtyWrapper">
                  <span>1600</span> <span className="qtyText">zestawów</span>
                </div>
              </AwardsHeaderComplectsQty>
            </AwardsHeaderComplectsQtyWrapper>
          </AwardsColDescImage>
        </AwardsRow>
      </AwardsContainer>
    </AwardsWrapper>
  )
}

export default Awards
