import React from 'react'
import styled from 'styled-components'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  TitleMixin,
  PaddingsContainerMixin,
} from './elements'

import rulesBottomDescBG from '../images/rules-bottom-desc-bg.svg'

const RulesWrapper = styled.div`
  position: relative;
  z-index: 100;
  padding-top: 50px;
  padding-bottom: 80px;
  margin-top: -35px;
  &:after,
  &:before {
    content: '';
    position: absolute;
    right: 5%;
    bottom: 0;
    display: block;
    width: 70%;
    height: 50px;
    background-color: ${(props) => props.theme.colorGreen};
    margin-bottom: -25px;
    transform: skew(15deg, 0);
    z-index: 100;
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 40px;
      margin-bottom: -20px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      height: 30px;
      margin-bottom: -15px;
    }
    @media (max-width: 575px) {
      height: 20px;
      margin-bottom: -10px;
    }
  }
  &:after {
    width: 40%;
    right: 0;
    transform: skew(0deg, 0);
  }
`

const RulesInnerWrapper = styled.div`
  overflow: hidden;
`

const RulesContainer = styled.div`
  ${ContainerMixin}
`

const RulesRow = styled.div`
  ${RowMixin}
`

const RulesCol = styled.div`
  ${ColMixin}
`

const RulesColTitle = styled(RulesCol)`
  ${PaddingsContainerMixin}/* overflow: hidden; */
`

const RulesTitle = styled.h2`
  ${TitleMixin}
  text-align: center;
  padding-bottom: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 30px;
  }
  @media (max-width: 575px) {
    margin-top: 20px;
  }
  .text {
    position: relative;
    display: inline-block;
    .line {
      position: absolute;
      display: block;
      width: 2000px;
      height: 46px;
      top: 50%;
      background-color: #008139;
      margin-top: -23px;
      @media (min-width: 992px) and (max-width: 1199px) {
        height: 40px;
        margin-top: -20px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 36px;
        margin-top: -18px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        height: 34px;
        margin-top: -17px;
      }
      @media (max-width: 575px) {
        height: 28px;
        margin-top: -14px;
      }
      &.left {
        left: 0;
        transform: skew(-15deg, 0);
        margin-left: -2045px;
        @media (min-width: 992px) and (max-width: 1199px) {
          margin-left: -2040px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          margin-left: -2035px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          margin-left: -2030px;
        }
        @media (max-width: 575px) {
          margin-left: -2015px;
        }
      }
      &.right {
        right: 0;
        transform: skew(-15deg, 0);
        margin-right: -2045px;
        @media (min-width: 992px) and (max-width: 1199px) {
          margin-right: -2040px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          margin-right: -2035px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          margin-right: -2030px;
        }
        @media (max-width: 575px) {
          margin-right: -2015px;
        }
      }
    }
  }
`

const RulesColHeaderDesc = styled(RulesCol)`
  ${DescMixin}
  text-align: center;
  padding-top: 80px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 20px;
  @media (max-width: 575px) {
    padding-top: 20px;
  }
  a {
    text-decoration: underline;
  }
  small {
    display: block;
    font-size: 18px;
    margin-top: 10px;
  }
  .bottomDesc {
    background-image: url(${rulesBottomDescBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 64px;
    font-weight: 900;
    font-style: italic;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 54px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 40px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 26px;
      margin-top: 40px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }
    @media (max-width: 340px) {
      font-size: 18px;
    }
  }
`

const RulesRowDesc = styled(RulesRow)`
  ${PaddingsContainerMixin}
  margin-left: -30px;
`

const RulesColDesc = styled(RulesCol)`
  ${DescMixin}
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  ${(props) =>
    props.numberColum === 1 &&
    `
    padding-top: 220px;
  `}
  ${(props) =>
    props.numberColum === 2 &&
    `
    padding-top: 110px;
  `}
  @media (min-width: 1200px) and (max-width: 1380px) {
    ${(props) =>
      props.numberColum === 1 &&
      `
      padding-top: 160px;
    `}
    ${(props) =>
      props.numberColum === 2 &&
      `
      padding-top: 80px;
    `}
  }
  @media (min-width: 992px) {
    padding-right: 80px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 45px;
    ${(props) =>
      props.numberColum === 1 &&
      `
      padding-top: 140px;
    `}
    ${(props) =>
      props.numberColum === 2 &&
      `
      padding-top: 70px;
    `}
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 30px;
  }

  a {
    text-decoration: underline;
  }
`

const RulesColDescBottom = styled(RulesCol)`
  ${DescMixin}
  ${PaddingsContainerMixin}
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 120px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 90px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 70px;
  }
  @media (max-width: 575px) {
    padding-top: 60px;
  }
  p {
    strong {
      display: block;
      margin-bottom: -40px;
      @media (min-width: 1381px) and (max-width: 1599px) {
        margin-bottom: -25px;
      }
      @media (min-width: 1200px) and (max-width: 1380px) {
        margin-bottom: -25px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: -20px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: -15px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        margin-bottom: -15px;
      }
      @media (max-width: 575px) {
        font-size: 20px;
        margin-bottom: -10px;
      }
    }
  }
`

const Rules = () => {
  return (
    <RulesWrapper id="rules">
      <RulesInnerWrapper>
        <RulesContainer>
          <RulesRow>
            <RulesColTitle>
              <RulesTitle>
                <span className="firstText">
                  <span className="text">
                    <span className="line left" />
                    <span>ZASADY AKCJI</span>
                    <span className="line right" />
                  </span>
                </span>
              </RulesTitle>
            </RulesColTitle>
          </RulesRow>
          <RulesRow>
            <RulesColHeaderDesc>
              <p>
                W ramach nowej akcji specjalnej <strong>RALLYEXPERTS</strong>{' '}
                czeka
                <br />
                <strong>8 tygodniowych etapów – Odcinków Specjalnych</strong> –
                na końcu każdego z nich masz szansę wygrać
                wyjątkowe nagrody – <strong>Niezbędnik RALLYEXPERTA</strong>.
              </p>
              <p>
                Nagrody za OSy zostaną przyznane 200 uczestnikom, którzy w danym
                tygodniu zarejestrowali
                <br />
                największą liczbę punktów z Kuponów Bonusowych REPXPERT.
                <br />
                <strong>
                Nagroda główna – sportowo-rajdowy event na torze Tomka Kuchara Driveland lub markowa automatyczna ultradźwiękowa myjnia narzędziowa<sup>*</sup>
                </strong>{' '}
                zostanie przyznana 60 uczestnikom, którzy najwięcej razy
                znaleźli się w czołówce Odcinka Specjalnego.
                <small><sup>*</sup>forma nagrody jest uzaleźniona od sytuacji epidemicznej w kraju.</small>
              </p>
              <p>
                Szczegółowy opis zasad znajduje się w{'\u00A0'}
                <strong>
                  <a href="/regulamin-akcji.pdf" target="_blank">
                    Regulaminie.
                  </a>
                </strong>
              </p>
              <p className="bottomDesc">3…, 2…, 1…, START!</p>
            </RulesColHeaderDesc>
          </RulesRow>
          <RulesRowDesc>
            <RulesColDesc numberColum={1}>
              <p className="numberRule">
                <span className="number">
                  <span>1</span>
                </span>
                <span className="title">
                  <span>KUPUJ</span>
                </span>
              </p>
              <p className="withNumberRule">
                regularnie dowolne produkty LuK, INA, FAG u{'\u00A0'}jednego z
                {'\u00A0'}autoryzowanych Dystrybutorów lub{'\u00A0'}Partnerów
                Schaeffler Polska Sp.{'\u00A0'}z{'\u00A0'}o.o.
              </p>
            </RulesColDesc>
            <RulesColDesc numberColum={2}>
              <p className="numberRule">
                <span className="number">
                  <span>2</span>
                </span>
                <span className="title">
                  <span>REJESTRUJ</span>
                </span>
              </p>
              <p className="withNumberRule">
                punkty z{'\u00A0'}Kuponów Bonusowych REPXPERT. Pamiętaj – każdy
                zarejestrowany kupon przybliża Cię do nagrody tygodnia!
                <br />
                W każdym z Odcinków Specjalnych masz szansę zdobyć wyjątkową
                nagrodę z Niezbędnika RALLYEXPERTA.
              </p>
            </RulesColDesc>
            <RulesColDesc numberColum={3}>
              <p className="numberRule">
                <span className="number">
                  <span>3</span>
                </span>
                <span className="title">
                  <span>
                    ODBIERAJ
                    <br /> NAGRODY
                  </span>
                </span>
              </p>
              <p className="withNumberRule">
                W{'\u00A0'}każdym z{'\u00A0'}etapów prowadzony jest ranking
                punktowy – jeżeli zgromadzone przez Ciebie punkty plasują Cię w
                TOP 200 – zostajesz jednym ze{'\u00A0'}zwycięzców Odcinków
                Specjalnych i{'\u00A0'}nagroda tygodnia jest Twoja.
                <br />
                Ale to{'\u00A0'}nie wszystko – walcz dalej rejestrując następne
                zakupy i{'\u00A0'}wygrywając kolejne OSy, dzięki czemu
                zwiększasz swoje szanse na nagrodę główną.
              </p>
            </RulesColDesc>
          </RulesRowDesc>

          <RulesRow>
            <RulesColTitle>
              <RulesTitle>
                <span className="lastText">
                  <span className="text">
                    <span className="line left" />
                    <span>WERYFIKACJA I WYDANIE NAGRÓD</span>
                    <span className="line right" />
                  </span>
                </span>
              </RulesTitle>
            </RulesColTitle>
          </RulesRow>

          <RulesRow>
            <RulesColDescBottom>
              <p>
                <strong>Nagroda główna</strong>
              </p>
              <p>
                Weryfikacja punktacji odbędzie się w terminie podanym w Regulaminie.
                <br />
                Terminy realizacji nagrody głównej ustalone zostaną
                indywidualnie z nagrodzonymi uczestnikami.
              </p>
              <p>
                <strong>Nagrody tygodniowe</strong>
              </p>
              <p>
                Weryfikacja punktacji odbywać się będzie w ciągu tygodnia po
                zakończeniu poszczególnych etapów.
              </p>
              <p>
                Wysyłka nagród tygodniowych odbywać się będzie w ciągu 2 tygodni
                od zakończenia etapu.
              </p>
              <p>
                Akcja skierowana jest do przedsiębiorców prowadzących warsztat
                naprawy pojazdów, którzy dokonują zakupów produktów marek: LuK,
                INA, FAG u Autoryzowanych Dystrybutorów lub Partnerów Schaeffler
                Sp. z 0.0. <br />
                Uczestnicy akcji zobowiązani są do wyrażenia zgody na
                komunikacje marketingową w portalu REPXPERT.
              </p>
            </RulesColDescBottom>
          </RulesRow>
        </RulesContainer>
      </RulesInnerWrapper>
    </RulesWrapper>
  )
}

export default Rules
