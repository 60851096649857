import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Awards from '../components/awards'
import Rules from '../components/rules'
import Account from '../components/account'
import Contacts from '../components/contacts'
import Footer from '../components/footer'

const IndexPage = (props) => (
  <Layout
    title="Schaeffler RALLYEXPERTS 2.0"
    description="Rejestruj regularnie punkty bonusowe REPXPERT, WYGRYWAJ CO TYDZIEŃ INNĄ NAGRODĘ, ZBIERZ CAŁĄ KOLEKCJĘ i zmierz się z innymi w rajdowej rywalizacji w Subaru Impreza."
  >
    <Header location={props.location} />
    <Awards />
    <Rules />
    <Account />
    <Contacts />
    <Footer />
  </Layout>
)

export default IndexPage
